.back-div {
  background-image: url("./media/buildings.jpeg");
  background-color: white;
  background-size: cover;
  filter: opacity(100%);
}

.video-container2 {
  width: 100%;
  position: relative;
  text-align: center;
  color: white;
}

.video-container2 video {
  position: relative;
  z-index: 0;
}

@media only screen and (min-width: 900px) {
  .footWidth {
    width: 75%;
  }
}

@media only screen and (max-width: 899px) {
  .footStyle {
    color: black !important;
  }
  .footWidth {
    width: 100%;
  }
}