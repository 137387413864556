@font-face {
  font-family: "AvenirBlack";
  src: local("AvenirBlack"), url("./fonts/avenir/Avenir-Black.otf");
  font-weight: bold;
}

@font-face {
  font-family: AvenirLight;
  src: local("AvenirLight"), url("./fonts/avenir/Avenir-Light.otf");
}

body,
h2,
h3,
h4,
h5,
h6,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: "AvenirBlack" !important;
    color: #FF9C4B;
}

h1,
h2,
.h1,
.h2 {
  font-family: "AvenirBlack" !important;
}

p, .p, div, .div{
  font-family: "AvenirLight" !important;
  font-size: 1.3rem;
  color: #a52317;
}

nav a{
    font-family: "AvenirLight" !important;
    font-size: 1rem;
    color: #a52317 !important;
    
}
