video {
  width: 100%;
  height: auto;
}

.crop-height {
  /* max-width: 1200px; /* img src width (if known) */
  max-height: 320px;
  overflow: hidden;
}

.bg-image-cropped {
  /* for small devices */
  /*background-image: url("../public/media/office.jpeg");*/
  /* lt ie8 */
  -ms-background-position-x: center;
  -ms-background-position-y: bottom;
  background-position: center;
  /* scale bg image proportionately */
  background-size: cover;
  /* ie8 workaround - http://louisremi.github.io/background-size-polyfill/ */
  /*-ms-behavior: url(/backgroundsize.min.htc);*/
  /* prevent scaling past src width (or not) */
  /* max-width: 1200px; */
}

.invisible {
  visibility: hidden;
}

.transparent {
  /* trigger hasLayout for IE filters below */
  zoom: 1;
  /* 0 opacity in filters still displays layout */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=10);
  opacity: 0;
}

/* example media query for smaller non-retina devices */
@media only screen and (max-device-width: 600px) and (-webkit-max-device-pixel-ratio: 1),
  only screen and (max-device-width: 600px) and (max-device-pixel-ratio: 1) {
  .bg-image-wedding {
    background-image: url("../public/media/office.jpeg");
  }
}

/* example media query for retina ipad and up */
@media only screen and (min-device-width: 768px) and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-device-width: 768px) and (min-device-pixel-ratio: 1.5) {
  .bg-image-wedding {
    background-image: url("../public/media/office.jpeg");
  }
}

.bg-video-cropped {
  /* for small devices */
  /*background-image: url("../public/media/office.jpeg");*/
  /* lt ie8 */
  -ms-background-position-x: center;
  -ms-background-position-y: bottom;
  background-position: center;
  /* scale bg image proportionately */
  background-size: cover;
  /* ie8 workaround - http://louisremi.github.io/background-size-polyfill/ */
  /*-ms-behavior: url(/backgroundsize.min.htc);*/
  /* prevent scaling past src width (or not) */
  /* max-width: 1200px; */
}

.video-container {
  width: 100%;
  position: relative;
  text-align: center;
  color: white;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.parallax {

  /* Set a specific height */
  min-height: 500px;
}

.video-container video {
  position: relative;
  z-index: 0;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .imgVidHeight {
    width: auto;
    height: 150px;
    padding-left: 0px;
  }
}

@media only screen and (min-width: 900px) {

  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
}

.height-special-img {
  height: 650px;
  object-fit: cover;
}

.height-special-div{
  display: flex;
  height: 160px;
  justify-content: center;
  align-items: center;
}

.partners{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 350px;
}

.partners-div{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}