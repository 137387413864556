@media only screen and (min-width: 900px) {
  .headerStyle {
    width: 355px !important;
  }
}

@media only screen and (max-width: 899px) {
  .headerStyle {
    width: 280px !important;
  }
}
